//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'
import useExternalLink from '~/composables/useExternalLink'

export default defineComponent({
  name: 'IconAndLabel',
  props: {
    label: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    icon: {
      type: [String, Object],
      default: ''
    },
    hoverSlide: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const { linkTarget, displayLink } = useExternalLink(props.link)

    return { linkTarget, displayLink }
  }
})
